.nav{
    height: fit-content !important;
    margin-top: 3em;
    position: relative;
}
.open{
    height: 400px !important;
}
.brand{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5em 1.5em 0em 1.5em;
    background: linear-gradient(0deg, rgba(56,56,56,0) 0%, rgba(0,0,0,0.22034751400560226) 100%);
}
.rotate{
    transform: rotate(-90deg);
}
.brand h1{
    font-weight: 400;
    font-size: 5em;
    margin: 0;
    text-align: center;
    padding: 1em;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    position: relative;
}
.selected{
    border-bottom: 2px solid rgb(255, 3, 3);
    margin: 0em 0.5em;
}
.pblc {
    margin-bottom: 3em;
    position: relative;
}
.pblc a{
    color: inherit;
    text-decoration: none;
}
.pblc ul{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;
    transition: 0.5s all;
    height: 0px;
}
.pblc li{
    list-style: none;
    font-size: 1.5em;
    text-align: center;
    cursor: pointer;
    transition: 0.3s all;
    position: relative;
}
.pblc li:hover{
    transform: scale(1.1);
    margin: 0em 0.5em;
}
.hambi{
    display: none !important;
    transition: 0.3s all;
}
.bullets{
    display: flex;
    flex-direction: row;
    gap: 0.2em;
    position: absolute;
    top: 70%;
    left: 0;
    height: 80px;
    width: 80px;
}
.bullets_r{
    display: flex;
    flex-direction: row;
    gap: 0.2em;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: rotateY(-180deg);
    height: 80px;
    width: 80px;
}
.bullets img:first-child,.bullets_r img:first-child{
    opacity: 0.2;
    filter: blur(3px);
    animation: lilMoves 3s alternate infinite linear;
}
.bullets img:nth-child(2),.bullets_r img:nth-child(2){
    opacity: 1;
    filter: blur(1px);
    animation: lilMoves 3s alternate infinite linear;
}
.logo{
    width: 60%;
    object-fit: cover;
}
@keyframes lilMoves {
    100%{
        transform: translate(-10%,-10%);
    }
}
.path{
    display: none;
}
@media (max-width: 767px) {
    .path{
        display: flex;
        margin: 0;
        font-size: 1.5em;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        white-space: nowrap;
        transition: 0.3s all;
    }
    .logo{
        width: 300px;
    }
    .bullets{
        width: 60px;
        height: 60px;
        display: none;
    }
    .bullets_r{
        width: 60px;
        height: 60px;
        display: none;
    }
    .brand h1{
        font-size: 2em;
        align-self: center;
        margin: 0;
    }
    .hambi img{
        transform: rotate(90deg);
        width: 40px;
        height: 40px;
    }
    .hambi{
        display: block !important;
    }
    .pblc li{
        white-space: nowrap;
    }
    .pblc ul{
        overflow-x: scroll;
        flex-direction: column;
        padding: 0;
    }
}