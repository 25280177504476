.InformPage{
    padding: 2em 15vw;
}

.InformPage a{
    color: #153D73;
    font-weight: bold;
}

.InformPage li{
    font-family: 'Montserrat', sans-serif;
    margin-left: 1em;
}

.InformPage p{
    font-size: 1em;
    font-weight: 300;
    font-family: 'Montserrat', sans-serif;
}
.InformPage h2,.InformPage h3{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}
.InformPage h1{
    font-family: 'Montserrat', sans-serif;
    font-size: 2em;
    font-weight: 700;
}