.packages h1{
    text-align: center;
    margin: 1em 0 0 0;
    padding: 0;
    font-size: 3em;
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
    position: relative;
}
.packages h2{
    margin-top: 0.5em;
    font-size: 1rem;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
}
.BookingDetails{
    width: "100%";
    padding: 1em;
    font-family: 'Montserrat', sans-serif;
}
.BookingDetails .BodyWrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.BookingDetails .BodyWrapper li{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.BookingDetails .BodyWrapper hr{
    width: 95%;
}
.BookingDetails .Book{
    background-color: #de0000;
    font-family: "Oswald",sans-serif;
    font-size: 1.5em;
    font-weight: 500;
    padding: .5em 2em;
    border-radius: .25em;
    border: none;
    color: whitesmoke;
}
.BookingDetails .TopWrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.BookingDetails h3{
    font-size: 1.75rem;
    font-weight: 600;
}
.BookingDetails .Summary{
    padding: 1em;
}
.BookingDetails .Dates{
    font-weight: 700;
    font-size: 1.5em;
    border-radius: .25em;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: .25em;
}
.star{
    -webkit-filter: blur(2px);
    filter: blur(2px);
    height: -moz-fit-content;
    left: 50%;
    opacity: .1;
    pointer-events: none;
    position: absolute;
    -webkit-transform: translate(-50%);
    width: 100px;
    z-index: 5;
}
.packageImgInner{
    overflow: scroll;
    max-height: 300px;
}
.packageCard h3{
    z-index: 10;
    background-color: #1F2124;
    padding: 0;
    margin: 0;
}
.packages h1::after{
    content: "Shooting packages";
    position: absolute;
    top:45%;
    left:33%;
    opacity: 0.3;
    filter: blur(4px);
}
.packageWrapper{
    padding: 2.5em 5em 5em 5em;
}
.packageLil h1{
    font-size: 1.5em;
}
.packageLil h2,.packageLil h3,.packageLil h4,.packageLil h5,.packageLil p,.packageLil th,.packageLil td{
    font-size: 100%;
}
.packageCard table{
    pointer-events: none;
}
.packageCard p{
    font-weight: 400;
}
.packageCard:hover{
    transform: scale(1.03);
}
.noTransform{
    transform: none !important;
}
.packageCard hr{
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}
.packageCard h5{
    color: #f65252;
    /*#F6B352*/
}
.packageCard h3,h5{
    text-align: center;
    font-weight: 300;
    padding: 0em 0em;
}
.packageCard td{
    font-weight: 300;
}
.packageCard th{
    font-weight: 400;
}
.packageCard td:first-child,.packageCard th:first-child{
    text-align: left;
    padding-right: 0.5em;
    margin-right: 0.5em;
}
.packageCard td:nth-child(2),.packageCard th:nth-child(2){
    text-align: center;
}
.packageCard th,.packageCard td{
    text-align: right;
    white-space: nowrap;
}
.invisible{
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-1000%,-1000%);
    width: 0;
    height: 0;
}
.acceptedCards:hover{
    opacity: 1;
}
.acceptedCards{
    margin: 1em 0em 2em 0em;
    opacity: .8;
    transition: .3s all;
}
.flippedInstanceName{
    position: absolute;
    top: 0;
    left: 0;
    padding: .25em 1em;
    background: #0000002e;
    font-family: 'Montserrat';
    font-weight: 500;
    width: 100%;
}
.packageCardWrap{
    padding: .3em !important;
}
.loaderCenter{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
}
.packageCard:not(.noTransform) {
    height: 500px;
    overflow: hidden;
}
.packageData{
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10000;
    background: #1f2124;
    padding-top: 1em;
    padding-bottom: .5em;
}
.packageCard{
    background-color: #1F2124;
    border-radius: .5em;
    transition: .2s all;
    color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 0em 1.5em 1.5em 1.5em;
    margin-bottom: 1em;
    max-width: 100%;
    perspective: 1000px;
    overflow-x: hidden;
}

.cardFront {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1em;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}


.book {
    z-index: 10;
    all: unset;
    width: 90%;
    height: 60px;
    font-size: 3em;
    margin-bottom: 0.6em;
    background: transparent;
    font-weight: 500;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    border: none;
    position: relative;
    color: #f0f0f0;
    cursor: pointer;
    z-index: 1;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  .book::after,
  .book::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -99999;
    transition: all .4s;
  }
  
  .book::before {
    transform: translate(0%, 0%);
    width: 100%;
    height: 100%;
    background: #2a2c30;
    border-radius: 10px;
  }
  
  .book::after {
    transform: translate(10px, 10px);
    width: 35px;
    height: 35px;
    background: #ffffff15;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 50px;
  }
  
  .book:hover::before {
    transform: translate(5%, 20%);
    width: 110%;
    height: 110%;
  }
  
  .book:hover::after {
    border-radius: 10px;
    transform: translate(0, 0);
    width: 100%;
    height: 100%;
  }
  
  .book:active::after {
    transition: 0s;
    transform: translate(0, 5%);
  }

.packageTable{
    padding: 0;
    margin: 0;
}
.details input,.details textarea{
    background-color: rgb(241, 237, 237);
    width: 100%;
}
.quantityInput::after{
    content: 'pcs';
    position: absolute;
    top: 50%;
    left: 80%;
    color: black;
    font-family: 'Montserrat', sans-serifMonteserrat;
    transform: translate(-50%, -50%);
    font-weight: 500;
    opacity: .5;
}
.InspectWeapon:hover{
    transform: scale(1.03);
}
.InspectWeapon,.BookNow{
    background: linear-gradient(180deg, rgba(31,33,36,1) 0%, rgba(42,44,48,1) 100%);
    background-color: #2a2c30;
    border-radius: 12px;
    color: whitesmoke;
    font-weight: 300;
    width: 100%;
    padding: 0.2em 2em;
    margin: 1em auto;
    transition: 0.5s all;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    gap: .5em;
    border: none;
    justify-content: center;
    align-items: center;
}
.BookNow{
    background: linear-gradient(180deg, rgb(89 10 9) -10%, rgb(175 19 17) 100%);
}
.quantityInput{
    position: relative;
    margin-left: .3em;
}
.quantityInput input[type="number"]{
    background-color: rgb(241, 237, 237) !important;
    padding: .5em;
}
.details{
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.checkOutCard.selected{
    background-color: #f65252;
}
.checkOutCard p{
    margin: 0;
}
.checkOutCard{
    background-color: #36383a; 
    padding: .5em 1em;
    border-radius: .5em;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    white-space: nowrap;
    cursor: pointer;
    opacity: .8;
    margin-bottom: .3em;
}
.checkOutCardButtons{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.checkOutCardButtons p{
    border-radius: .3em;
    text-align: center;
    user-select: none;
    cursor: pointer;
    margin: 0;
    transition: .3s all;
}
.checkOutCardButtons p:hover{
    transform: scale(1.1);
}
.checkOutCardButtons p:first-child{
    background-color: #f652528c;
}
.checkOutCardButtons p:last-child{
    background-color: #f65252;
}
.packageCard h4{
    font-weight: 300;
    opacity: .8;
    padding-bottom: .5em;
}
.promo input:not(input[type="checkbox"]){
    background-color: rgb(241, 237, 237);
    width: 100%;
    padding: .5em 1em;
    height: 3em;
}
.selector{
    padding-bottom: .5em;
}
.steril{
    position: relative;
    margin: 0;
}
.price{
    width: 100%;
    align-self: flex-end;
    text-align: end;
    margin-top: .7em;
}
.goodToKnow{
    font-weight: 400;
    text-align: center;
    padding: 1em;
    font-family: 'Montserrat', sans-serif;
}
@media (max-width: 767px) {
    .packageWrapper{
        padding: .5em 2em;
    }
    .book{
        margin-top: 1em;
    }
    .details{
        padding: 1em 0em;
    }
}
.single tr th,.single tr td{
    text-align: center !important;
}

.availablePeopleCount{
    background: #F65251;
    margin: 0;
    border-radius: .5em;
    padding: .25em 1em !important;
}

.packageCountIllustrationWrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
}

.packageCountIllustration{
    opacity: .7;
}

.packageCountIllustration span{
    font-weight: 200;
    font-size: .75em;
}

.testAlertText,.validateError{
    background-color: #ae1212;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    padding: .5em;
    font-size: 1em;
    margin-top: 1em;
}