.Booking{
    padding: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    color: white;
    background: radial-gradient(circle, rgba(255,95,94,1) 0%, rgba(220,1,0,1) 100%);
    width: fit-content;
    margin: auto;
    margin-top: 2em;
    margin-bottom: 5em;
    border-radius: .45em;
}
.Booking h3{
    font-weight: 300;
    font-size: 1.5em;
    padding-left: .25em;
    border-left: 1px solid white;
    border-radius: .2em;
}
.BookingCardHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
}
.BookingCardHeader span{
    font-size: 2em;
    font-weight: 700;
}
.BookingCardBody p{
    margin: 0;
}
.BookingDetails{
    margin-top: .5em;
}
.BookingDetails p{
    display: flex;
    flex-direction: row;
    gap: .5em;
    font-weight: 300;
}
.BookingDetails strong{
    font-weight: 500;
}
.fourhundredfour{
    display: flex;
    flex-direction: row;
    gap: 1em;
    color: white;
    font-size: 1.3em;
}
.confirmed{
    margin: 0;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    margin-top: 2em;
}
@media only screen and (max-width: 600px) {
    .Booking{
        margin: 1em;
        font-size: 1em;
    }
}