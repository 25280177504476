.controllers i:hover{
    transform: scale(1.1);
}
.controllers i{
    position: absolute;
    transition: 0.3s all;
    font-size: 4em;
    cursor: pointer;
}
i.bal{
    top:50%;
    left:-10%;
}
i.jobb{
    top:50%;
    right: -10%;
}
.weapons{
    min-height: 100vh;
    padding: 2em 20%;
    font-family: 'Montserrat', sans-serif;
}
.weapons h2{
    font-weight: 800;
    text-transform: uppercase;
}
.weapons hr{
    width: 100%;
    margin: 0;
    padding: 0;
    margin-top: 1em;
    background: transparent;
    height: 1px;
    border-radius: 0.5em;
    opacity: 1;
}
.hrBlue{
    box-shadow: 0px 5px 5px #163C73;
}
.hrRed{
    box-shadow: 0px 5px 5px #DC0100;
}
.wepCard{
    background: #1F2124;
    color: whitesmoke;
    padding: 2em;
    border-radius: .5em;
}
.weapon{
    height: 400px;
    border-radius: 0.25em;
    margin-bottom: 1em;
    object-fit: cover;
}
.weaponWrap{
    margin-bottom: 2em;
    position: relative;
}
.wepCol{
    transition: 0.3s all;
}
@media (max-width: 767px) {
    .weapons{
        padding: 1em 2em;
    }
    .controllers i{
        font-size: 2em;
    }
    i.bal{
        left:-5%;
    }
    i.jobb{
        right: -5%;
    }
    .weapon{
        height: fit-content;
        max-height: 200px;
    }
}