.admin{
    padding: 2em;
}
.bookings{
    max-width: 70%;
    font-family: 'Montserrat', sans-serif;
}
.bookings h1 b {
    white-space: nowrap;
}
.bookings h3,.bookings p{
    margin: 0;
}
.bookings h1{
    font-weight: 500;
}
.itemsText{
    margin-top: 1em;
    opacity: 0.6;
    align-self: center;
}
.booking{
    background: #163c73;
    padding: 0.75em 1.5em;
    color: whitesmoke;
    border-radius: .5em;
    cursor: pointer;
    transition: 0.3s all;
    z-index: 0;
}
.booking:hover{
    margin-left: 1em;
}
.bgRadius{
    display: flex;
    gap: .5em;
    flex-direction: row;
    max-width: 100%;
    overflow: auto;
    margin-top: 1em;
}
.bgRadius p{
    padding: .25em 1em;
    font-size: .75em;
    font-weight: 500;
    background-color: #ff0402bd;
    border-radius: 1em;
    white-space: nowrap;
}
.popUpWrapper{
    transition: 0.3s all;
    z-index: 100000000;
}
.popUp{
    position: fixed;
    top:50%;
    left:50%;
    background-color: white;
    padding: 1em;
    transform: translate(-50%,-50%);
    z-index: 1000000;
    width: fit-content;
    min-width: 60%;
    max-width: 80%;
    border-radius: .45em;
    height: fit-content;
    overflow: auto;
    transition: 0.3s all;
}
.backdrop{
    position: fixed;
    top:0;
    left:0;
    background-color: rgb(0, 0, 0);
    height: 100vh;
    width: 100vw;
    opacity: .5;
}
.listIcons i{
    background-color: rgb(9 31 61);
    padding: 0.5em;
    color: whitesmoke;
    width: 30px;
    border-radius: .3em;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    display: flex;
}
.searchButton{
    width: fit-content !important;
    margin: 0 !important;
    height: 50px;
    padding: 0.7em !important;
}
.search{
    width: 100%;
    margin: 0;
    height: 50px;
}
.membershipContacts section{
    border-radius: 0.3em;
    max-width: 100%;
    overflow: auto;
}

.membershipContacts{
    font-family: 'Montserrat', sans-serif;
    padding: 0em 10em;
}
.membershipContacts th,.membershipContacts td{
    text-align: left;
    padding: .2em 1em;
}
.membershipContacts td{
    white-space: nowrap;
}
.membershipContacts th{
    color: black;
    white-space: nowrap;
}
.membershipContacts th:nth-child(even) {
    background: #c4cdddad;
}
.membershipContacts th:nth-child(odd) {
    background: #b3bccdad;
}
.membershipContacts{
    display: flex;
    flex-direction: column;
    gap: 2em;
    margin-bottom: 10vh;
}
.settings input[type="number"]{
    text-align: left;
}
.settings{
    font-family: 'Montserrat', sans-serif;
    max-width: 100%;
    margin-bottom: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.editWeapons h1{
    font-weight: 400;
}
.editWeapons,.editPackages{
    margin: auto;
    margin-top: 5em;
    max-width: 70%;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 10vh;
}
.editPackages .empty{
    margin: 1em;
    border-radius: .3em;
    opacity: .7;
}
.publicWeapon img{
    height: 100px;
    object-fit: cover;
}
.packageItem{
    background-color: #1F2124;
    border-radius: .3em;
    color: white;
    padding: .5em;
    font-weight: 600;
}
.packageBox{
    background-color: #1F2124;
    border-radius: .3em;
    color: white;
    padding: .5em;
}
.packageItemDelete{
    background-color: #dc0100;
    font-size: .8;
    opacity: .8;
    transition: 0.3s all;
    width: fit-content;
    margin: 0;
    border-radius: .1em;
    user-select: none;
}
.packageItemDelete:hover{
    transform: scale(.9);
    cursor: pointer;
    opacity: 1;
}
.packageBox p{
    margin: 0;
}
.packageBody{
    padding: .5em;
}
.firstLetterUppercase{
    margin: 0;
    text-transform: capitalize;
}
.weaponImg{
    border-radius: .2em;
}
.add:hover{
    opacity: 1;
}
.add{
    transition: 0.3s all;
    background-color: #dc0100;
    border-radius: 50%;
    opacity: .8;
    width: 30px;
    font-size: 1.7em;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
    cursor: pointer;
}
.admin button[type="button"]{
    border: none;
    font-size: .7em;
    padding: 1em;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    border-radius: .35em;
    color: white;
    background-color: #858f9e;
}
.delete:hover{
    color: rgba(255, 0, 0, 0.504);
}
.delete{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%,-50%);
    color: black;
    z-index: 10000;
    transition: 0.3s all;
    cursor: pointer;
}
.saveChanges{
    width: fit-content !important;
}