.Home{
    background-color: black;
    color: white;
    height: fit-content;
    max-height: fit-content;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.movingDiv {
    display: flex;
    flex-direction: row;
    gap: 1em;
    max-width: 100vw;
    font-size: 4em;
    font-weight: 900;
    position: absolute;
    text-transform: uppercase;
    opacity: 0.3;
    filter: blur(1px);
    pointer-events: none;
}
.movingDiv:first-child{
    top:-3%;
    left:0;
    animation: moveingDiv 100s alternate infinite linear;
}
.movingDiv:nth-child(2){
    bottom:-4%;
    left:0;
    animation: moveingDivRev 100s alternate infinite linear;
}
.movingDiv p{
    white-space: nowrap;
}
.content{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    flex-direction: column;
    padding-top: 2em;
    padding-bottom: 2em;
}
.lilabout{
    font-size: 1em;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
}

.book {
  all: unset;
  width: 400px;
  height: 120px;
  font-size: 3em;
  margin-bottom: 0.6em;
  background: transparent;
  font-weight: 500;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  border: none;
  position: relative;
  color: #f0f0f0;
  cursor: pointer;
  z-index: 1;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.book::after,
.book::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -99999;
  transition: all .4s;
}

.book::before {
  transform: translate(0%, 0%);
  width: 100%;
  height: 100%;
  background: #28282d;
  border-radius: 10px;
}

.book::after {
  transform: translate(10px, 10px);
  width: 35px;
  height: 35px;
  background: #ffffff15;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 50px;
}

.book:hover::before {
  transform: translate(5%, 20%);
  width: 110%;
  height: 110%;
}

.book:hover::after {
  border-radius: 10px;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
}

.book:active::after {
  transition: 0s;
  transform: translate(0, 5%);
}

.guns{
  display: flex;
  justify-content: center;
  align-items: center;
}
.guns img{
  position: absolute;
}
.guns img:first-child{
  transform: rotate(90deg) rotateY(200deg);
  bottom:35%;
  left:-30%;
}
.guns img:nth-child(2){
  transform: rotate(90deg) rotateX(180deg);
  bottom:35%;
  right: -28%;
}
.Track{
  position: absolute;
  width: 500px;
  opacity: 0.4;
  right: -15%;
  top: -10%;
  transform: rotate(30deg);
}
.Track:nth-child(2){
  top:70%;
  left:-20%;
}
.TrackBack{
  width: 100%;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}
.Kardtyavar{
  display: flex;
  gap: 0.5em;
  flex-direction: row;
  max-width: 100%;
  overflow-x: scroll;
}
.Kardtyavar .Kardtya:first-child .lilCard:first-child{
  background-image: url('./homeimg1.png');
  color: white;
}
.Kardtyavar .Kardtya:nth-child(2) .lilCard:first-child{
  background-image: url('./homeimg2.png');
  color: white;
}
.Kardtyavar .Kardtya:nth-child(3) .lilCard:first-child{
  background-image: url('./homeimg3.jpg');
  color: white;
}
.Kardtya{
  width: 300px;
  max-width: 300px;
  position: relative;
  z-index: 10;
}
.stats{
  background-color: white;
  position: relative;
  width: 70%;
  min-height: 300px;
  margin-bottom: 5em;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  padding: 2em;
  gap: 2em;
}
.stats h3{
  font-weight: 600;
}
.stats p{
  font-weight: 300;
}
.lilCard{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 350px;
  background-color: #d2d3d3;
  padding: 2em;
  background-size: cover;
  background-position: center;
  border-radius: 0.25em;
  user-select: none;
}
.lilCard:hover .Backdrop{
  opacity: 0.2;
}
.Backdrop{
  position: absolute;
  width: 100%;
  border-radius: 0.5em;
  height: 100%;
  bottom: 0;
  background-color: black;
  filter: blur(4px);
  opacity: 0.6;
  z-index: 5;
  transition: 0.3s all;
}
.lilCard h1{
  font-size: 5em;
  font-weight: 800;
  z-index: 10;
}
.lilCard p{
  font-size: 1.5em;
  text-align: center;
  white-space: nowrap;
  z-index: 10;
}
@keyframes moveingDivRev {
    100%{
        transform: translate(0%,0%);
    }
    0%{
        transform: translate(-200%,0%);
    }
}
@keyframes moveingDiv {
    0%{
        transform: translate(0%,0%);
    }
    100%{
        transform: translate(-200%,0%);
    }
}
@media (max-width: 767px) {
  .Kardtyavar{
    display: flex;
    gap: 1em;
    overflow-x: scroll;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    max-width: 100%;
  }
  .book{
    height: 70px;
    width: 200px;
    font-size: 2em;
  }
  .Kardtya{
    width: 100%;
  }
  .Kardtyavar::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  .Kardtya:last-child{
    margin-right: 2em;
  }
  .content{
    padding: 2em;
  }
  .content p{
    font-size: 0.7em;
  }
  .guns img:first-child{
    display: none;
  }
  .guns img:nth-child(2){
    display: none;
  }
  .stats{
    width: 100%;
    margin-bottom: 0em;
  }
}
.alreadyLoggedin{
  padding: 2em;
}
.login p.error{
  font-family: 'Montserrat', sans-serif;
  font-size: 0.75em;
  color: red;
  opacity: 0.8;
  text-align: center;
  padding: 0;
  margin: 0;
}