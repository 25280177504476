.aboutUs{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1em;
    padding: 2em 15vw;
    margin-bottom: 5em;
}
.aboutUs p{
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
    font-size: 1.2em;
}
.aboutUs h1{
    text-align: left;
    text-transform: uppercase;
}
a{
    color: black;
    text-decoration: none;
}
.faq{
    background-color: black;
    color: white;
    padding: 2em 15vw;
}
.faqBlock p{
    font-size: 1em;
}
.faqBlock h2{
    font-weight: 500;
    font-size: 1.5em;
}
.faqBlock{
    padding: 1em 1em 1em 0em;
    font-family: 'Montserrat', sans-serif;
}
.Membership ul{
    margin: 0;
    padding: 0;
}
.Membership li{
    list-style: none;
    padding: 0em 0em .4em 0em;
    margin: .35em 0;
    font-size: 1.2em;
    font-family: 'Montserrat', sans-serif;
    border-radius: 15%;
    border-bottom: 2px solid #dd0000;
    width: fit-content;
}
.Membership p{
    font-size: 1.2em;
    font-weight: 300;
    font-family: 'Montserrat', sans-serif;
}
.Membership h2{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}
.Membership h1{
    font-family: 'Montserrat', sans-serif;
    font-size: 4em;
    font-weight: 700;
}
.Membership{
    padding: 2em 15vw;
}
.Registration h1{
    font-size: 4em;
}
.Registration p{
    font-size: 1em;
    font-weight: 500;
}
.submit{
    margin-top: 1.5em;
    border: none;
    padding: 1em 2em;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    border-radius: .35em;
    color: white;
    background-color: #dd0000;
    width: 200px;
}
@media (max-width: 767px) {
    .Membership h1{
        font-size: 2.5em;
    }
    .submit{
        width: 100%;
    }
}