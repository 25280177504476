.contact{
    background-color: rgb(231, 231, 231);
    background: linear-gradient(0deg, rgba(41,68,92,0) 0%, rgba(231,231,231,0.3617822128851541) 16%, rgba(231,231,231,1) 48%)
}
.contact h1{
    position: relative;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    padding: 0.3em 0em;
    font-size: 5em;
    font-weight: 700;
}
.contact h1::before{
    content: 'Contact';
    position: absolute;
    top: 70%;
    left: 50%;
    font-size: 1.75em;
    opacity: 0.1;
    transform: translate(-50%,-50%);
    max-width: 100vw;
    overflow: hidden;
}
.content p{
    margin: 0;
    font-size: 1.2em;
    text-align: center;
}
.content p b{
    font-weight: 500;
}
.bcard .content{
    border-radius: 0.45em;
    background-color: white;
    padding: 1em;
    z-index: 10;
    font-family: 'Montserrat', sans-serif;
}
.content h2{
    font-weight: 700;
    text-align: center;
}
hr{
    margin: 0.5em auto 0.5em auto;
    opacity: 0.1;
    width: 70%;
}
.content a{
    color: inherit;
    transition: 0.3s all;
    text-decoration: none;
}
.content a:hover{
    color: rgb(197, 17, 17);
}
.content:hover{
    transform: scale(1.02);
}
.content{
    height: 100%;
    transition: 0.3s all;
}
.content form{
    padding: 1em;
    height: 100%;
}
.content input[type="text"],.content input[type="email"],.content textarea{
    border: none;
    padding: .45em;
    border-left: 2px solid black;
    border-bottom: 1px solid rgb(226, 226, 226);
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    resize: none;
    margin-bottom: .45em;
    outline: none;
}
input:focus{
    border: none;
    outline: none;
}
.content input[type="button"]{
    font-family: 'Montserrat', sans-serif;
    border: none;
    width: 250px;
    color: white;
    margin-top: 0.35em !important;
    background: black;
    padding: 0.25em;
    border-radius: 0em .45em 0em .45em;
}
.footerText{
    text-align: center;
    padding: 1em 0em 2em 0em;
}
@media (max-width: 767px) {
    .contact h1{
        font-size: 3em;
    }
    .bcard .content{
        border-radius: 0 !important;
    }
    .contact h1::before{
        content: 'Contact';
        position: absolute;
        top:70%;
        left:50%;
        font-size: 1.6em;
        opacity: 0.1;
        transform: translate(-50%,-50%);
    }
    .content:hover{
        transform: none;
    }
}