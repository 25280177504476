textarea:active,textarea:focus,input:focus,input:active{
    outline: 2px solid #f6525296;
}
form input{
    margin-bottom: 1em;
}
input{
    width: 100%;
}
ul{
    padding-left: 0;
}
select:focus{
    outline: 1px solid #dd0000;
}
select:active{
    outline: none;
}
.pr-1{
    padding-right: .3em !important;
}
.form-check-input:focus{
    outline: none !important;
    box-shadow: none;
}
.form-check-input:checked {
    background-color: #f65251;
    border-color: #680303;
}
.form-check-label {
    font-family: 'Oswald', sans-serif;
    font-weight: 200;
    font-size: 1em;
    color: #d2d3d3;
}
.react-datepicker-wrapper{
    width: 100% !important;
}
.react-datepicker-popper{
    z-index: 100000 !important;
}
.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover{
    background-color: transparent !important;
    color: black !important;
    font-weight: bold !important;
}
.react-datepicker__week:nth-child(odd) {
    background: #3034351a !important;
    border-radius: 0.5em !important;
    padding: 0em 1em !important;
}
.react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today, .react-datepicker__year-text--today{
    opacity: 0.3 !important;
    font-weight: 300 !important;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
    background-color: #ae1212 !important;
    border-radius: 50% !important;
}
.react-datepicker {
    font-size: 0.8rem !important;
    background-color: white !important;
    color: black !important;
    border: 1px solid #303435 !important;
    border-radius: 0.45rem !important;
    display: inline-block !important;
    position: relative !important;
    font-family: 'Montserrat', sans-serif !important;
}
select{
    border-radius: .3em;
    padding: .7em;
    border: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    margin-bottom: 1em;
    background-color: rgb(241 237 237);
    width: 100%;
    height: 3em;
}
input[type="text"],input[type="email"],textarea,input[type="number"],input[type="password"],input[type="date"]{
    border-radius: .3em;
    padding: .7em;
    border: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    background-color: #ebd3cf91;
}
.rbc-button-link{
    height: 30px;
    width: 30px;
    text-align: center;
    background-color: transparent !important;
    color: black !important;
}
.rbc-toolbar button:hover {
    color: #ffffff;
    background-color: #a9a9a9;
}
.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
    background-color: #888888b8;
}
input[type="button"]{
    margin-top: 1.5em;
    border: none;
    padding: 1em 2em;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    border-radius: .35em;
    color: white;
    background-color: #dd0000;
    width: 100%;
  }
input[type="button"] .secondary{
    background-color: #ebd3cf91;
}
  input:disabled {
    opacity: .7;
}
  .rbc-event {
    background-color: #163c73;
}
  select option:checked {
    background-color: transparent;
    color: black;
    font-weight: 700;
    border-left: 2px solid #f65251;
    border-radius: 0.20em;
    padding-left: 0.50em;
    transition: 0.3s all;
  }
  .pagination {
    width: fit-content;
    margin: 1em 0em 0em 0em;
    padding: .3em;
    border-radius: .25em;
    display: flex;
    flex-direction: row;
    border-bottom: 3px solid red;
    gap: .4em;
}
.secondary{
    background-color: #6363634a !important;
}
.info{
    opacity: 0.5;
}
.pagination li {
    background: #163c7382;
    padding: .2em .5em;
    border-radius: .25em;
    color: white;
    font-weight: 700;
    min-width: 25px;
    text-align: center;
}
.pagination .active {
    background: #c52632f7;
}
.pagination * {
    color: white;
}
::-webkit-scrollbar {
    height: 10px;
    width: 10px;
}
::-webkit-scrollbar-track {
    background-color: transparent; 
}
::-webkit-scrollbar-thumb {
    background-color: #888888;
    border-radius: .5em;
}
.pm-0{
    padding-bottom: 0;
}
.test-alert{
    position: fixed;
    top: 0;
    left: 0;
    text-align: center;
    width: 100vw;
    padding: .5em;
    background-color: #ae1212;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    z-index: 100000;
}
.fullscreen-group {
    width: 100%;
    height: 100%;
}
.popup-content {
    background: linear-gradient(90deg, rgba(31,33,36,1) 0%, rgb(47 47 47) 100%) !important;
    border: none!important;
    border-radius: .25em!important;
    color: #f5f5f5!important;
    margin: auto!important;
    padding: 5px!important;
    width: 50%!important;
}

.form-check-label a {
    color: #468cec;
    font-weight: 400;
}
.temporary-unavailable p {
    font-weight: 100 !important;
}
.temporary-unavailable h3 {
    font-weight: 500 !important;
    text-align: start !important;
}
.temporary-unavailable i {
    width: 50px;
    height: 50px;
    color: #d04849;
    font-size: 2.5em;
    border: 2px solid white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
}
.temporary-unavailable {
    padding: 1em;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2em;
    border: 4px dotted rgba(208, 72, 73, 0.71);
    border-radius: 1em;
}
@media (max-width: 767px) {
    .popup-content{
        width: 90% !important;
    }
}